import React, { useState, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { Box, Typography, TextField, Button, Popper } from '@mui/material';
import PocEstimationModal from './poc_estimation_modal.component';
import PocEstimationModalCost from './poc_cost_modal.component';

const PocHeaderComponent = (props) => {
    const [openPocModal, setOpenPocModal] = useState(false);
    const [openPocModalCost, setOpenPocModalCost] = useState(false);

    const { totalRouteCost,handleLockRoute,selectRoute,
        // setcostPerMeter,costPerMeter,
            selectedRouteAdditional,routeSelection, setRouteSelection,
            mapMarkers, setMapMarkers} = props
    const handleClose = () => {
        setOpenPocModal(false)
    }
    
    // useEffect(()=>{
    //     const fetchRouteCosts = async () => {
    //        try {
    //          let routesData = await getRouteCostsForPoc(selectedRouteAdditional);
    //          setTotalRouteCost(routesData); // Save the fetched routes
    //        } catch (error) {
    //          console.error('Error fetching routes:', error);
    //        }
    //      };
     
    //      fetchRouteCosts();
    //      },[selectedRouteAdditional])
    const handleOpenPocModal = () => {
        setOpenPocModal(true)
    }
    const handleCloseCost = () => {
        setOpenPocModalCost(false)
    }
    const handleOpenPocModalCost = () => {
        setOpenPocModalCost(true)
    }
    return (
        <>
            <PocEstimationModal open={openPocModal} handleClose={handleClose} selectRoute={selectRoute} selectedRouteAdditional={selectedRouteAdditional} />
            {/* <PocEstimationModalCost open={openPocModalCost} handleClose={handleCloseCost} handleLockRoute={handleLockRoute} setcostPerMeter={setcostPerMeter} costPerMeter={costPerMeter} /> */}

            <Box
                sx={{
                    backgroundColor: '#192126',
                    border: '1px solid #333333',
                    color: '#fff',
                    height: '50px',
                    fontFamily: 'Inter, sans-serif',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: '12px',
                        mx: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '12px',
                            alignItems: 'center',
                        }}
                    >
                        {routeSelection ?
                        (<Button variant="contained" sx={{
                            backgroundColor: '#3A474F',
                            fontSize: '11px',
                            '&:hover': {
                                border: '1px solid #bd1d23',
                                fontSize: '11px',
                                // backgroundColor: '#bd1d23',
                                color: 'white',
                                boxShadow: 'inset 0 0 10px #bd1d23'
                            }
                        }}
                            className='table-icon'
                            onClick={() => 
                                // handleOpenPocModalCost()
                                handleLockRoute()
                            }
                        >Lock Route</Button>):
                        (<Button variant="contained" sx={{
                            backgroundColor: '#3A474F',
                            fontSize: '11px',
                            '&:hover': {
                                border: '1px solid #bd1d23',
                                fontSize: '11px',
                                // backgroundColor: '#bd1d23',
                                color: 'white',
                                boxShadow: 'inset 0 0 10px #bd1d23'
                            }
                        }}
                            className='table-icon'
                            onClick={() =>{
                                setRouteSelection(true)}
                                // handleLockRoute()
                            }
                        >Select Route</Button>)}

                      {mapMarkers.length==2&&<Button variant="contained" sx={{
                            backgroundColor: '#3A474F',
                            fontSize: '11px',
                            '&:hover': {
                                border: '1px solid #bd1d23',
                                fontSize: '11px',
                                // backgroundColor: '#bd1d23',
                                color: 'white',
                                boxShadow: 'inset 0 0 10px #bd1d23'
                            }
                        }}
                        // disabled={mapMarkers.length<2 ? false : true}
                            className='table-icon'
                            onClick={handleOpenPocModal}
                        >ADD COST</Button>}
                        <Typography>
                            {totalRouteCost}
                        </Typography>                        
                    </Box>
                    {routeSelection && <Button variant="contained" sx={{
                            backgroundColor: '#3A474F',
                            fontSize: '11px',
                            '&:hover': {
                                border: '1px solid #bd1d23',
                                fontSize: '11px',
                                // backgroundColor: '#bd1d23',
                                color: 'white',
                                boxShadow: 'inset 0 0 10px #bd1d23'
                            }
                        }}
                            className='table-icon'
                            onClick={() =>{
                                setRouteSelection(false)}
                                // handleLockRoute()
                            }
                        >CANCEL</Button>}
                </Box>
            </Box>
        </>
    );
};

export default PocHeaderComponent;