import React, { useState, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { getRoutesForPoc,getAdditionalCostsForPoc } from './functions/get_routes_for_poc.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import { notify } from '../../common/functions/react-toastify.function';
import { decodeGeometry } from '../../common/functions/decode_geometry_to_geojson.function';

mapboxgl.accessToken = 'pk.eyJ1IjoidXNtYW4tZ2hhbmkiLCJhIjoiY2xoaGNqODllMGFkZTNtcHIxMXlqcGUwdCJ9.430W3OsMNhVeWxBNktOutA';  // Replace with your access token

const MapWithDirections = ({ setSelectedRoute, selectedRoute, selectedRouteAdditional, setSelectedRouteAdditional,setTotalRouteCost,routeSelection,setRouteSelection,mapMarkers,setMapMarkers }) => {
  const [map, setMap] = useState(null);
  const [routes, setRoutes] = useState([]);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  // const [markers, setMarkers] = useState([]); // State to store selected markers

console.log(routes)
  // Fetch routes data from the API
  // useEffect(() => {
  //   const fetchRoutes = async () => {
  //     try {
  //       let routesData = await getRoutesForPoc();
  //       setRoutes(routesData); // Save the fetched routes
  //     } catch (error) {
  //       console.error('Error fetching routes:', error);
  //     }
  //   };

  //   fetchRoutes();
  // }, []);

  useEffect(() => {
    // Initialize map instance
    const initializeMap = () => {
      const mapInstance = new mapboxgl.Map({
        container: 'map', // ID of the HTML element
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-74.5, 40], // Initial center point (e.g., New York)
        zoom: 10,
      });
      mapInstance.addControl(new mapboxgl.NavigationControl());

      setMap(mapInstance); // Set the map instance in the state

      // Add routes on map load
      mapInstance.on('load', () => {
        // First, handle the selected route (if any)
        if (selectedRoute) {
          const routeGeoJSON = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: decodeGeometry(selectedRoute?.geometry), // Decode the selected route geometry
            },
          };

          if (!mapInstance.getSource('route')) {
            mapInstance.addSource('route', {
              type: 'geojson',
              data: routeGeoJSON,
            });

            mapInstance.addLayer({
              id: 'route-layer',
              type: 'line',
              source: 'route',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#008000',
                'line-width': 5,
              },
            });
          }
        }
   
          
            
        const fetchRoutes = async () => {
          try {
            let routesData = await getRoutesForPoc();
            routesData.forEach((route, index) => {
              console.log(route)
              const routeGeoJSON = {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: route.geoJSON.coordinates, // Assuming geoJSON is directly available
                },
              };

              // Add a larger hitbox layer for the route (invisible, but larger clickable area)
              // Add an invisible hitbox layer for the route
              if (!mapInstance.getSource(`route-hitbox-${index}`)) {
                mapInstance.addSource(`route-hitbox-${index}`, {
                  type: 'geojson',
                  data: routeGeoJSON,
                });

                mapInstance.addLayer({
                  id: `route-hitbox-layer-${index}`,
                  type: 'line',
                  source: `route-hitbox-${index}`,
                  layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                  },
                  paint: {
                    'line-color': 'transparent', // Make it invisible
                    'line-width': 5, // Increase the width for the hitbox
                  },
                });

              }
              // Check if source for the route already exists
              if (!mapInstance.getSource(`route-${index}`)) {
                
                mapInstance.addSource(`route-${index}`, {
                  type: 'geojson',
                  data: routeGeoJSON,
                });
    
                mapInstance.addLayer({
                  id: `route-layer-${index}`,
                  type: 'line',
                  source: `route-${index}`,
                  layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                  },
                  paint: {
                    'line-color': "#fc6f03",
                    'line-width': 5,
                  },
                });
              }

            
          // Add hover effect for cursor pointer
            mapInstance.on('mouseenter', `route-hitbox-layer-${index}`, () => {
              mapInstance.getCanvas().style.cursor = 'pointer'; // Change cursor to pointer on hover
            });

            // Reset cursor when mouse leaves the route
              mapInstance.on('mouseleave', `route-hitbox-layer-${index}`, () => {
              mapInstance.getCanvas().style.cursor = ''; // Reset cursor style
            });
              mapInstance.on('click', `route-hitbox-layer-${index}`, (e) => {
                setSelectedRouteAdditional(route.id)
                setTotalRouteCost(route.totalCost)
                setRouteSelection(false)
                console.log(`Route clicked: ${route.id}`);
                const clickedPoint = e.lngLat; // Get clicked point's coordinates
                   console.log("markers===>",mapMarkers)
                   setMapMarkers((prevState)=>{
                    console.log("markers===>",prevState)

                    if (prevState.length >= 2) {
                      // If two markers are already present, clear them
                      prevState.forEach((m) => m.marker.remove());
                      console.log("====markers====",prevState)
                      return [];
                    }
                  else if (prevState.length < 2) {
                    // Create a new marker
                    const marker = new mapboxgl.Marker()
                      .setLngLat([clickedPoint.lng, clickedPoint.lat])
                      .addTo(mapInstance);
                    console.log("here")
                    // Update markers state
                    return [...prevState, { lng: clickedPoint.lng, lat: clickedPoint.lat, marker }];
  
                    // If we have two markers, fetch the route between them
                    if (prevState.length === 1) {
                      fetchRouteBetweenMarkers(prevState[0], { lng: clickedPoint.lng, lat: clickedPoint.lat });
                    }
                  }
  
                   })
              //  new mapboxgl.Marker()
              //   .setLngLat([clickedPoint.lng, clickedPoint.lat])
              //   .addTo(mapInstance);
              });
          
            });
            let additional =  await getAdditionalCostsForPoc()
            additional.response.forEach((route,index)=>{
              const routeGeoJSON = {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: route.geoJSON.coordinates, // Assuming geoJSON is directly available
                },
              };
              if (!mapInstance.getSource(`additional-route-${index}`)) {
                
                mapInstance.addSource(`additional-route-${index}`, {
                  type: 'geojson',
                  data: routeGeoJSON,
                });
    
                mapInstance.addLayer({
                  id: `additional-route-layer-${index}`,
                  type: 'line',
                  source: `additional-route-${index}`,
                  layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                  },
                  paint: {
                    'line-color': "#000000",
                    'line-width': 5,
                  },
                });
              }

            })
          } catch (error) {
            console.error('Error fetching routes:', error);
          }
        };
        fetchRoutes()
        mapInstance.on('click', `route-layer`, (e) => {
          setSelectedRouteAdditional(null)
          setTotalRouteCost(null)
          setRouteSelection(false)
         
        });
      });
    


          // Deselect route on click outside the route layers
          mapInstance.on('click', (e) => {
            // Check if the click is inside any of the route layers
            const features = mapInstance.queryRenderedFeatures(e.point, { layers: ['route-layer', 'route-hitbox-layer'] });
            console.log('selectedRouteAdditional===>',selectedRouteAdditional)

            if (!features.length) {
              // If no features (routes) are clicked, set selectedRoute to null
              setSelectedRoute(null);
              // setSelectedRouteAdditional(null);
              // setTotalRouteCost(null);
              
            }
          });
        
    
    };

    if (!map) initializeMap(); // Initialize the map only once

  }, [map, selectedRoute, routeSelection]); // Re-run when routes or selectedRoute changes
  console.log("markersOutside===>",mapMarkers)

  useEffect(() => {
    if (map) {
      // Create a reference for the directions control
      let directions;

      if (routeSelection) {
        directions = new MapboxDirections({
          accessToken: mapboxgl.accessToken,
          unit: 'metric',
          profile: 'mapbox/driving',
          alternatives: true,
        });

        map.addControl(directions, 'top-left');

        // Handle route change in the directions control
        directions.on('route', (e) => {
          if (e.route && e.route.length > 0) {
            const route = e.route[0];
            console.log('e.route==>', route);
            setSelectedRoute(route); // Set selected route in state
          }
        });
      } else {
        // Remove the directions control if it exists
        if (directions) {
          map.removeControl(directions);
        }
      }

      // Cleanup function to remove the control when the component unmounts or routeSelection changes
      return () => {
        if (directions) {
          map.removeControl(directions);
        }
      };
    }
  }, [map, routeSelection, setSelectedRoute]);

  useEffect(()=>{
    if(!map) return
    if (mapMarkers.length == 2) {
      fetchRouteBetweenMarkers(mapMarkers[0], mapMarkers[1]);
    }
    else{

      if (map.getLayer('route-plot')) {
        map.removeLayer('route-plot');  
        if (map.getSource('route')) {
          map.removeSource('route');
        }      
      }

    }
  },[mapMarkers])


  const fetchRouteBetweenMarkers = async (start, end) => {
    const directions = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
      unit: 'metric',
      profile: 'mapbox/driving',
      controls: {
        inputs: false, // This hides the input controls (start/end fields)
        instructions: false, // This hides the route instructions
      }
    });

    directions.setOrigin([start.lng, start.lat]);
    directions.setDestination([end.lng, end.lat]);
  
    directions.on('route', (e) => {
      if (e.route && e.route.length > 0) {
        const route = e.route[0];
        console.log('e.route==>',e.route)
        setSelectedRoute(route);
        const routeGeoJSON = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: decodeGeometry(route?.geometry), // Decode the selected route geometry
          },
        };

        if (!map.getSource('route')) {
          console.log("-das=da=sd=asd=asd=as=d")
          map.addSource('route', {
            type: 'geojson',
            data: routeGeoJSON,
          });

          map.addLayer({
            id: 'route-plot',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#008000',
              'line-width': 8,
            },
          });
        }
      }

        

      
    });
  };



  return (
    <div>
      <div id="map" style={{ width: '100%', height: 'calc(100vh - 50px)' }}></div>  
    </div>
  );
};

export default MapWithDirections;
